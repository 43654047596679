import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import './Responsive.css';
import Login from './components/Login/Login';
import Register from './components/Login/Register';
import ForgetPassword from './components/Login/ForgetPassword';
import OtpVerification from './components/Login/OtpVerification';
import Success from './components/Login/Success';
import NewPassword from './components/Login/NewPassword';
import Home from './components/Home';
import Messages from './components/ChatBox/Messages';
import PrivateRoute from './PrivateRoute';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/otpverification" element={<OtpVerification />} />
        <Route path="/newpassword" element={<NewPassword />} />
        <Route path="/success" element={<Success />} />
        <Route element={<PrivateRoute />}>
          <Route path="/chat" element={<Messages />} />
          <Route path="/newton" element={<Home />} /> {/* Update the route here */}
          <Route path="/" element={<Navigate to="/newton" />} /> {/* Redirect to /newton */}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
