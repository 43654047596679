import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const BASE_URL = 'https://saas.ravian.ai/v1/api';

export default function NewPassword() {
  const [password, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    try {
      const token = localStorage.getItem('token');

      if (!token) {
        setError('No token found. Please login again.');
        return;
      }

      const response = await axios.post(
        `${BASE_URL}/user/reset-password`,
        { password },
        {
          headers: {
            'token': token,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        // Password reset successful
        navigate('/login'); // Redirect to login page
      } else {
        setError('Failed to reset password. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
      console.error('Error resetting password:', error);
    }
  };

  return (
    <div className="login-form d-flex justify-content-center align-items-center">
      <div className="wrapper">
        <div className="form">
          <div className="screen-effect"></div>
          <div className="glass-effect"></div>
          <div className="form-bigbox">
            <div className="formblock">
              <div className="form-inner-block">
                <div className="formbox">
                  <h1 className='text-center'>New Password</h1>
                  {error && <div className="alert alert-danger">{error}</div>}
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label className="form-label">New Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="New Password"
                        value={password}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="form-label">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                    </div>
                    <button type="submit" className="btn d-block mb-4">Continue</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}