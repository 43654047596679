import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

export default function OtpVerification() {
    const [timer, setTimer] = useState(10);
    const [canResend, setCanResend] = useState(false);
    const [otp, setOtp] = useState(['', '', '', '']);
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.email) {
            setEmail(location.state.email);
        }
    }, [location]);

    // ... timer useEffect remains the same

    const handleResendOtp = () => {
        // Logic to resend OTP goes here
        console.log("Resend OTP clicked");
        setTimer(30);
        setCanResend(false);
    };

    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        const otpString = otp.join('');
        const token = localStorage.getItem('token');

        if (!token) {
            setError("Authentication token not found. Please register again.");
            return;
        }

        try {
            const response = await axios.post(
                "https://saas.ravian.ai/v1/api/user/verify-otp",
                { email, otp: otpString },
                { headers: { 'token': token, } }
            );

            if (response.data && response.data.token) {
                console.log("OTP verified successfully");
                // Replace the old token with the new one
                localStorage.setItem('token', response.data.token);
                navigate("/");
            } else {
                setError("OTP verification failed. Please try again.");
            }
        } catch (err) {
            setError(err.response?.data?.message || "An error occurred. Please try again.");
        }
    };

    return (
        <div className="login-form d-flex justify-content-center align-items-center">
            <div className="wrapper">
                <div className="form">
                    <div className="screen-effect"></div>
                    <div className="glass-effect"></div>
                    <div className="form-bigbox">
                        <div className="formblock">
                            <div className="form-inner-block">
                                <div className="formbox">
                                    <h1 className="text-center">Verification</h1>
                                    <p className="mb-4">An OTP has been sent to {email}</p>
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3 text-center">
                                            <label className="form-label mb-4">Please enter OTP to verify</label>
                                            <div className="otp-input">
                                                {otp.map((digit, index) => (
                                                    <input
                                                        key={index}
                                                        type="number"
                                                        min="0"
                                                        max="9"
                                                        value={digit}
                                                        onChange={(e) => handleOtpChange(index, e.target.value)}
                                                        required
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                        <div className="text-center mt-4">
                                            <p className="mb-4">
                                                {canResend ? (
                                                    <button onClick={handleResendOtp} className="resend-link">Resend OTP</button>
                                                ) : (
                                                    <>Resend OTP in {timer}s</>
                                                )}
                                            </p>
                                        </div>
                                        <button type="submit" className="btn d-block mb-4">Verify</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}