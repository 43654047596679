import React, { useState, useEffect, useRef } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import axios from 'axios';

export default function Messages({ threadId, filePath, chatId, initialChatHistory }) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isReceivingMessages, setIsReceivingMessages] = useState(false);
  const [codeBlocks, setCodeBlocks] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isWebSocketReady, setIsWebSocketReady] = useState(false);

  const websocketRef = useRef(null);
  const inputRef = useRef(null);
  const wsMessagesRef = useRef([]);
  const promptRef = useRef('');
  const chatBoxRef = useRef(null);

  const base_url = 'https://saas.ravian.ai/v1/api';
  const authkey = localStorage.getItem('token');

  useEffect(() => {
    if (initialChatHistory && initialChatHistory.length > 0) {
      const formattedMessages = initialChatHistory.flatMap(chat => [
        {
          text: chat.prompt,
          sender: 'user',
          time: new Date(chat.createdAt).toLocaleTimeString(),
        },
        ...chat.response.map(msg => ({
          text: msg.content,
          sender: msg.name,
          time: new Date(chat.createdAt).toLocaleTimeString(),
        }))
      ]);
      setMessages(formattedMessages);
      wsMessagesRef.current = formattedMessages;

      const initialCodeBlocks = formattedMessages.flatMap(msg => extractCodeBlocks(msg.text));
      setCodeBlocks(initialCodeBlocks);
    } else {
      setMessages([]);
      wsMessagesRef.current = [];
      setCodeBlocks([]);
    }
  }, [threadId, initialChatHistory]);

  useEffect(() => {
    let ws = null;
    if (chatId) {
      ws = new WebSocket(`wss://core.ravian.ai/v1/ws/${chatId}`);
      websocketRef.current = ws;

      ws.onopen = () => {
        console.log('Connected to WebSocket');
        setIsWebSocketReady(true);
        ws.send(`${filePath}`);
      };

      ws.onmessage = async (event) => {
        try {
          const message = JSON.parse(event.data);
      
          if (message && typeof message === 'object') {
            if (message.content) {
              if (message.name === 'user_proxy') {
                const newMessageData = {
                  text: `Hi ${localStorage.getItem('username')} <br />Your dataset has been loaded successfully. How may I assist you further?`,
                  sender: message.name,
                  time: new Date().toLocaleTimeString(),
                };
                setMessages((prevMessages) => [...prevMessages, newMessageData]);
                wsMessagesRef.current.push(newMessageData);
              } else if (message.name !== 'user_proxy' && message.name !== 'Planner' && message.name !== 'CodeWriter') {
                let newMessageData = {
                  text: message.content,
                  sender: message.name,
                  time: new Date().toLocaleTimeString(),
                };
      
                // Check for chart URL pattern
                const chartUrlRegex = /\/static\/[a-zA-Z0-9\-_]+_chart\.png/;
                const match = message.content.match(chartUrlRegex);
                
                if (match) {
                  const chartPath = match[0];
                  const fullChartUrl = `https://core.ravian.ai${chartPath}`;
      
                  // Validate that the image exists by checking its accessibility
                  const isImageAvailable = await checkImage(fullChartUrl);
      
                  if (isImageAvailable) {
                    newMessageData = {
                      text: `<img src="${fullChartUrl}" alt="Chart" style="max-width: 100%;" />`,
                      sender: message.name,
                      time: new Date().toLocaleTimeString(),
                    };
                  } else {
                    console.error('Image not available yet:', fullChartUrl);
                    newMessageData = {
                      text: 'Error: Chart image not found or not available yet.',
                      sender: message.name,
                      time: new Date().toLocaleTimeString(),
                    };
                  }
                }
      
                // NEW: Handle image paths without "/static/"
                const nonStaticChartRegex = /[a-f0-9\-]{36}_chart\.png/;
                const nonStaticMatch = message.content.match(nonStaticChartRegex);
                
                if (nonStaticMatch) {
                  const chartFilename = nonStaticMatch[0];
                  const fullChartUrl = `https://core.ravian.ai/static/${chartFilename}`; // Assuming charts are served from '/static/'
      
                  // Validate that the image exists by checking its accessibility
                  const isImageAvailable = await checkImage(fullChartUrl);
      
                  if (isImageAvailable) {
                    newMessageData = {
                      text: `<img src="${fullChartUrl}" alt="Chart" style="max-width: 100%;" />`,
                      sender: message.name,
                      time: new Date().toLocaleTimeString(),
                    };
                  } else {
                    console.error('Image not available yet:', fullChartUrl);
                    newMessageData = {
                      text: 'Error: Chart image not found or not available yet.',
                      sender: message.name,
                      time: new Date().toLocaleTimeString(),
                    };
                  }
                }
      
                setMessages((prevMessages) => [...prevMessages, newMessageData]);
                wsMessagesRef.current.push(newMessageData);
      
                const newCodeBlocks = extractCodeBlocks(message.content);
                if (newCodeBlocks.length > 0) {
                  setCodeBlocks((prevCodeBlocks) => [...prevCodeBlocks, ...newCodeBlocks]);
                }
              }
            } else if (Array.isArray(message)) {
              message.forEach((msg) => {
                if (msg.content && msg.name) {
                  const newMessageData = {
                    text: msg.content,
                    sender: msg.name,
                    time: new Date().toLocaleTimeString(),
                  };
                  setMessages((prevMessages) => [...prevMessages, newMessageData]);
                  wsMessagesRef.current.push(newMessageData);
                }
              });
            } else {
              console.warn('Received message with unexpected format:', message);
            }
          } else {
            // console.warn('Received non-object message:', message);
          }
      
          if (!message || (!message.content && !Array.isArray(message))) {
            saveChatContent(promptRef.current);
            setIsReceivingMessages(false);
          }
        } catch (e) {
          console.error('Error parsing or processing message:', e);
          console.error('Raw message data:', event.data);
        } finally {
          setIsLoading(false);
        }
      };
      
      // Helper function to check if an image exists
      async function checkImage(url) {
        try {
          const response = await fetch(url, { method: 'HEAD' });
          return response.ok;  // Returns true if the image is accessible
        } catch (error) {
          console.error('Error checking image availability:', error);
          return false;
        }
      }
      

      ws.onclose = () => {
        console.log('WebSocket Disconnected');
        setIsReceivingMessages(false);
        setIsWebSocketReady(false);
      };

      ws.onerror = (error) => {
        console.error('WebSocket Error:', error);
        setIsReceivingMessages(false);
        setIsWebSocketReady(false);
      };
    }

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [chatId, filePath]);

  const saveChatContent = async (prompt) => {
    try {
      const filteredResponses = wsMessagesRef.current.filter(
        (msg) => msg.sender !== 'user'
      );

      const response = await axios.post(`${base_url}/chat/create`, {
        threadId,
        prompt: prompt,
        response: filteredResponses.map((msg) => ({
          content: msg.text,
          name: msg.sender,
          role: msg.sender === 'system' ? 'system' : 'user',
        })),
      }, {
        headers: {
          'Content-Type': 'application/json',
          'authkey': authkey,
        }
      });

      if (!response.data.meta.status) {
        throw new Error('Failed to save chat content');
      }

      // console.log('Chat content saved successfully:', response.data);
    } catch (error) {
      console.error('Error saving chat content:', error);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !isWebSocketReady) return;

    const userMessage = {
      text: newMessage,
      sender: 'user',
      time: new Date().toLocaleTimeString(),
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    wsMessagesRef.current.push(userMessage);

    promptRef.current = newMessage;

    websocketRef.current.send(newMessage);
    setNewMessage('');
    setIsLoading(true);
    setIsReceivingMessages(true);

    inputRef.current.focus();
  };

  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      alert('Code copied to clipboard!');
    });
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const extractCodeBlocks = (content) => {
    const codeBlockRegex = /```(?:(\w+)\n)?([\s\S]*?)```/g;
    const blocks = [];
    let match;
    while ((match = codeBlockRegex.exec(content)) !== null) {
      blocks.push({
        language: match[1] || 'javascript',
        code: match[2].trim()
      });
    }
    return blocks;
  };

  const extractTable = (content) => {
    const tableRegex = /Code output:\s+((?:.*\n)+)/g;
    const tables = [];
    let match;

    while ((match = tableRegex.exec(content)) !== null) {
      const lines = match[1].trim().split('\n');
      const headers = lines[0].split(/\s{2,}/).map(header => header.trim());
      const rows = lines.slice(1).map(row => row.split(/\s{2,}/).map(cell => cell.trim()));

      tables.push({
        headers: headers,
        rows: rows
      });
    }

    return tables;
  };

  const removeCodeBlocks = (content) => {
    return content.replace(/```[\s\S]*?```/g, '(Code block)');
  };

  const renderMessage = (msg, index) => {
    if (!msg || typeof msg !== 'object') {
      console.error('Invalid message format:', msg);
      return null;
    }

    const messageText = typeof msg.text === 'string' ? msg.text.trim() : '';
    if (messageText === '') {
      console.warn('Empty message text:', msg);
      return null;
    }

    const messageWithoutCode = removeCodeBlocks(messageText);
    const hasCodeBlock = messageText !== messageWithoutCode;
    const tables = extractTable(messageText);

    return (
      <div key={index} className={msg.sender === 'user' ? 'your-message' : 'user-message'}>
        <span dangerouslySetInnerHTML={{ __html: messageWithoutCode }} />
        {hasCodeBlock && msg.sender !== 'user' && (
          <button className="toggle-drawer-button" onClick={toggleDrawer}>
            <img src='./images/code2.png' alt="View Code" />
          </button>
        )}
        {tables.length > 0 && (
          <div className="tablebox">
            {tables.map((table, tableIndex) => (
              <table key={tableIndex} className='table table-striped table-responsive'>
                <thead>
                  <tr>
                    {table.headers.map((header, headerIndex) => (
                      <th key={headerIndex}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {table.rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`chat-box ${drawerVisible ? 'drawer-open' : ''}`}>
      <div className="chats" ref={chatBoxRef}>
        {messages.map((msg, index) => renderMessage(msg, index))}
        <div className={`right-drawer ${drawerVisible ? 'visible' : 'hidden'}`}>
          <div className='drawer-heading'>
            <h2>Code Blocks</h2>
            <button className="close-drawer-button" onClick={closeDrawer}>
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="code-area">
            {codeBlocks.length > 0 ? (
              codeBlocks.map((block, index) => (
                <div key={index}>
                  <h3>Code Block {index + 1} <button onClick={() => copyToClipboard(block.code)} className="copy-button">
                    Copy Code</button></h3>
                  <SyntaxHighlighter language={block.language} style={okaidia}>
                    {block.code}
                  </SyntaxHighlighter>
                </div>
              ))
            ) : (
              <p>No code blocks available</p>
            )}
          </div>
        </div>
      </div>

      <div className="message-box">
        <form className="d-flex align-items-center" onSubmit={handleSendMessage}>
          <div className="msg-area">
            <input
              type="text"
              ref={inputRef}
              placeholder={isReceivingMessages ? "Newton is cooking..." : isWebSocketReady ? "Type a new message here..." : "Connecting..."}
              className="message"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              disabled={isReceivingMessages || !isWebSocketReady}
            />
          </div>
          <div className="msg-btn">
            <button type="submit" disabled={isReceivingMessages || !isWebSocketReady}>
              <i className={`fa ${isReceivingMessages ? 'fa-spinner fa-spin' : 'fa-paper-plane-o'}`}></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
