import React, { useState, useEffect } from 'react';
import ChatItem from './ChatItem';
import axios from 'axios';
import { useChatContext } from '../ChatBox/ChatContext';

const BASE_URL = 'https://saas.ravian.ai/v1/api';

export default function SideBar({ onNewChat, onSelectChat, isSidebarVisible, onToggleSidebar }) {
    const { chatThreads, setChatThreads } = useChatContext();  // Use the context
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedThreadId, setSelectedThreadId] = useState(null);

    useEffect(() => {
        fetchChatThreads();
    }, [currentPage]);

    const fetchChatThreads = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${BASE_URL}/thread/list?perPage=10&currentPage=${currentPage}`, {
                headers: {
                    'authkey': token
                }
            });

            if (response.data.meta.status) {
                setChatThreads(response.data.data);
                setTotalPages(response.data.pages);
            }
        } catch (error) {
            console.error('Error fetching chat threads:', error);
        }
    };

    const handleNewChat = async () => {
        onNewChat();
    };

    const fetchChatHistory = async (thread) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${BASE_URL}/chat/list?threadId=${thread._id}`, {
                headers: {
                    'authkey': token
                }
            });

            if (response.data.meta.status) {
                const chatHistory = response.data.data;
                setSelectedThreadId(thread._id);
                onSelectChat(thread, chatHistory);
            }
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };

    // New function to update chat threads
    const updateChatThreads = (newThread) => {
        setChatThreads(prevThreads => [newThread, ...prevThreads]);
    };

    return (
        <div className={`sidebar ${isSidebarVisible ? 'visible' : 'hidden'}`}>
            <h3>All Chats</h3>
            <div className="chat-list" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                {chatThreads.length > 0 ? (
                    chatThreads.map((thread) => (
                        <ChatItem
                            key={thread._id}
                            chatmsg={thread.title}
                            isSelected={thread._id === selectedThreadId}
                            onClick={() => fetchChatHistory(thread)}
                        />
                    ))
                ) : (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        color: 'white',
                    }}>
                        No chats to display
                    </div>
                )}
            </div>
            <button type="button" className="new-chat" onClick={handleNewChat}>New Chat</button>
        </div>
    );
}