import React, { useState } from 'react';
import MainChatWindow from '../components/ChatBox/MainChatWindow';
import Tabs from '../components/NavBar/Tabs';
import SideBar from '../components/SideBar/SideBar';
import { ChatProvider } from './ChatBox/ChatContext';

export default function Home() {
  const [isNewChat, setIsNewChat] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [selectedThread, setSelectedThread] = useState(null);
  const [sidebarThreads, setSidebarThreads] = useState([]);

  const handleNewChat = () => {
    setIsNewChat(true);
    setSelectedThread(null);
  };

  const handleSelectChat = (thread, chatHistory) => {
    setSelectedThread({
      threadId: thread._id,
      chatHistory: chatHistory,
      filePath: thread.file_path,
      chatId: thread.chat_id
    });
    setIsNewChat(false);
  };

  const handleToggleSidebar = () => {
    setIsSidebarVisible(prev => !prev);
  };

  const updateSidebarThreads = (newThread) => {
    setSidebarThreads(prevThreads => [newThread, ...prevThreads]);
  };

  return (
    <ChatProvider>
      <div className="app-container">
        <div className='bigbox'>
          <Tabs />
          <div className='smallbox'>
            <button className='toggle' onClick={handleToggleSidebar}>
              <i className="fa fa-bars" aria-hidden="true"></i>
            </button>
            <SideBar
              onNewChat={handleNewChat}
              onSelectChat={handleSelectChat}
              isSidebarVisible={isSidebarVisible}
              onToggleSidebar={handleToggleSidebar}
              chatThreads={sidebarThreads}
              setChatThreads={setSidebarThreads}
            />
            <MainChatWindow
              isNewChat={isNewChat}
              setIsNewChat={setIsNewChat}
              selectedThread={selectedThread}
              isSidebarVisible={isSidebarVisible}
              updateSidebarThreads={updateSidebarThreads}
            />
          </div>
        </div>
      </div>
    </ChatProvider>
  );
}